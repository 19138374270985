import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navigation from './navigation'
import Footer from './footer'
import '../assets/scss/page.scss'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            titleTemplate
            defaultTitle
            description
            keywords
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          titleTemplate={data.site.siteMetadata.titleTemplate}
          title={data.site.siteMetadata.title}
          defaultTitle={data.site.siteMetadata.defaultTitle} >
              <meta name='charset' content='utf-8' />
              <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no'/>
              <meta name='description' content={data.site.siteMetadata.description}/>
              <meta name='keywords' content={data.site.siteMetadata.keywords}/>
              <meta property='og:title' content={data.site.siteMetadata.title}/>
              <meta property='og:description' content={data.site.siteMetadata.description}/>
              <meta property='og:image' content='https://www.hummz.com/assets/img/og-img.jpg'/>
              <meta property='og:url' content={data.site.siteMetadata.siteUrl}/>
              <meta name='twitter:card' content='summary_large_image'/>
              <link rel="icon" href="./src/assets/images/favicon.png" type="image/x-icon"/>
        </Helmet>
        <Navigation  />
        {children}
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
