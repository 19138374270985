import React from 'react'
import { Link } from 'gatsby'

import logoLight from '../assets/images/iep-logo-white.svg'

const Footer = ({ props }) => (
  <>
    <footer className="footer footer-bg">
      <div className="container">
        <div className="row gap-y">
          <div className="col-xl-5 text-md-left">
            <p>
              <Link to="/" className="nav-link">
                <img className="logo-light align-bottom" src={logoLight} alt="Indus Equity Partners" style={{ height: '45px' }} />
                <span className="mb-0 ml-2 p-0 align-bottom font-weight-bold" style={{lineHeight:0.8}}>INDIA - UNITED STATES - AUSTRALIA</span>
              </Link>
            </p>
            <p className="ml-4">
              <small>
              Indus Equity Partners (IEP) is an India focused VC tech fund manager with a focus on med tech / health sciences and fin tech. We are a globally credentialled team of professionals with decades of experience across investments, operations and consulting.
              </small>
            </p>
            <hr className="d-xl-none" />
          </div>
          <div className="col-xl-3 ">
            <h6 className="mt-6 mb-4">
              <strong>USEFUL LINKS</strong>
            </h6>
            <div className="nav flex-column">
              <Link to="/about-us" className="nav-link">
                {' '}
                About Us{' '}
              </Link>
              <Link to="/investment-warehouse" className="nav-link">
                {' '}
                Investment Warehouse{' '}
              </Link>
              <Link
                to="/leadership-team-and-advisory-board"
                className="nav-link"
              >
                {' '}
                Team & Advisors{' '}
              </Link>
            </div>
          </div>
          <div className="col-xl-4">
            <h6 className="mt-6 mb-4">
              {' '}
              <strong>GET IN TOUCH</strong>{' '}
            </h6>
            {/* <p>
              604B Arcadia, 6th Floor, South City - II,<br/>Sector 49, Gurgaon - 122018, Haryana, India
            </p> */}
            <p>
              <i className="icon-envelope text-white" />{' '}
              info@indusequitypartners.com
            </p>
          </div>
        </div>
        <hr />
      </div>
    </footer>
    <footer className="footer footer-copyright-bg">
      <div className="container">
        <div className="row">
          <div className="col-6 text-center text-md-left">
            <small>© 2018-{new Date().getFullYear()} Indus Equity Partners. All rights reserved.</small>
          </div>
          <div className="col-6 text-center text-md-right">
            <div className="nav float-right">
              <Link to="/contact" className="nav-link">
                {' '}
                Contact Us{' '}
              </Link>
              <Link to="/privacy-policy" className="nav-link">
                {' '}
                Privacy Policy{' '}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
)

export default Footer
