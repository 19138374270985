import React from 'react'
// import { Link } from 'gatsby'
import logoLight from '../assets/images/iep-logo.png'
import logoDark from '../assets/images/iep-logo.png'
import './insideheader.css'
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem} from 'reactstrap';


class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: true,
    }
  }
  handleClick = (e, innerRef) => {
      this.setState(state => ({ isActive: !state.isActive }));
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  // <UncontrolledDropdown nav inNavbar setActiveFromChild>
  //                 <DropdownToggle nav caret tag="a" className="nav-link">Investment Warehouse</DropdownToggle>
  //                 <DropdownMenu right>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse"} activeclassname="active" id="investment-warehouse" key="investment-warehouse" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse">All</DropdownItem>
  //                   {/* <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/project-bakeme"} activeclassname="active" id="portfoliob" key="portfoliob" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/project-bakeme">Project BakeMe</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/project-medic"} activeclassname="active" id="portfoliom" key="portfoliom" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/project-medic">Project Medic</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/project-revconstruct"} activeclassname="active" id="portfolior" key="portfolior" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/project-revconstruct">Project Revconstruct</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/project-desserts"} activeclassname="active" id="portfoliod" key="portfoliod" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/project-desserts">Project Desserts</DropdownItem> */}
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/cancer-screening"} activeclassname="active" id="portfoliod" key="portfoliod" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/cancer-screening">Cancer Screening</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/ambulance-management"} activeclassname="active" id="portfoliod" key="portfoliod" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/ambulance-management">Ambulance Management</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/neuro-feedback-therapy"} activeclassname="active" id="portfoliod" key="portfoliod" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/neuro-feedback-therapy">Neuro Feedback Therapy</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/electric-vehicle-charging"} activeclassname="active" id="portfoliod" key="portfoliod" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/electric-vehicle-charging">Electric Vehicle Charging</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/uterine-fibroids-treatment"} activeclassname="active" id="portfoliod" key="portfoliod" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/uterine-fibroids-treatment">Uterine Fibroids Treatment</DropdownItem>
  //                   <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse/disrupting-digital-payments"} activeclassname="active" id="portfoliod" key="portfoliod" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse/disrupting-digital-payments">Disrupting Digital Payments</DropdownItem>
  //                 </DropdownMenu>
  //             </UncontrolledDropdown>
  //             <UncontrolledDropdown nav inNavbar setActiveFromChild>
  //               <DropdownToggle nav caret  >Team & Advisors</DropdownToggle>
  //               <DropdownMenu right>
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board"} activeclassname="active" id="leadershipall" key="leadershipall" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board">All</DropdownItem>
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/rajiv-bhatia"} activeclassname="active" id="leadershipr" key="leadershipr" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/rajiv-bhatia">Rajiv Bhatia</DropdownItem>
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/sumit-kochhar"} activeclassname="active" id="leaderships" key="leaderships" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/sumit-kochhar">Sumit Kochhar</DropdownItem>
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/samir-pandey"} activeclassname="active" id="leadershipd" key="leadershipd" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/samir-pandey">Samir Pandey</DropdownItem>
  //                 <DropdownItem divider />
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/mahinder-chugh"} activeclassname="active" id="leadershipm" key="leadershipm" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/mahinder-chugh">Mahinder Chugh</DropdownItem>
  //                 {/* <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/steve-chainani"} activeclassname="active" id="leadershipsc" key="leadershipsc" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/steve-chainani">Steve Chainani</DropdownItem>
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/gv-babu"} activeclassname="active" id="leadershipg" key="leadershipg" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/gv-babu">GV Babu</DropdownItem> */}
  //                 {/* <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/peter-munachen"} activeclassname="active" id="leadershipp" key="leadershipp" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/peter-munachen">Peter Munachen</DropdownItem> */}
  //                 {/* <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/anupam-karmakar"} activeclassname="active" id="leadershipak" key="leadershipak" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/anupam-karmakar">Dr. Anupam Karmakar</DropdownItem> */}
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/pratik-kothari"} activeclassname="active" id="leadershippk" key="leadershippk" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/pratik-kothari">Pratik Kothari</DropdownItem>
  //                 <DropdownItem active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board/kadambari-beelwar"} activeclassname="active" id="leadershipkb" key="leadershipkb" onClick={this.handleClick.bind(this, "leadership")} href="/leadership-team-and-advisory-board/kadambari-beelwar">Kadambari Beelwar</DropdownItem>
  //               </DropdownMenu>
  //             </UncontrolledDropdown>

  render() {
    return (
      <div>
        <Navbar ref={this.nav} expand="md" dark={true} data-navbar="fixed" className="navbar-stick-dark shadow-6" style={{ backgroundColor: '#FFFFFF' }} >
          <div className="container">
            <div className="navbar-left">
              <NavbarToggler onClick={this.toggle} className=" float-xs-right"/>
              <NavbarBrand href="/">
                <img className="logo-light align-bottom" src={logoLight} alt="IEP" style={{ height: '60px' }} />
                <img className="logo-dark align-bottom" src={logoDark} alt="IEP" style={{ height: '60px' }} />
              </NavbarBrand>
            </div>
            <Collapse isOpen={!this.state.isOpen} navbar>
              <Nav className="ml-auto nav nav-navbar" navbar>
                <NavItem>
                  <NavLink active={typeof window !== 'undefined' && window.location.pathname === "/"} activeclassname="active" id="home" value="home" key="home" onClick={this.handleClick.bind(this, "home")} href="/" >Home</NavLink>
                </NavItem>
                <NavItem >
                  <NavLink active={typeof window !== 'undefined' && window.location.pathname === "/about-us"} activeclassname="active" id="about-us" key="about-us" onClick={this.handleClick.bind(this, "about-us")} href="/about-us" >About Us</NavLink>
                </NavItem>
                <NavItem >
                  <NavLink active={typeof window !== 'undefined' && window.location.pathname === "/india-story"} activeclassname="active" id="india-story" key="india-story" onClick={this.handleClick.bind(this, "india-story")} href="/india-story" >India Story</NavLink>
                </NavItem>
                <NavItem >
                  <NavLink active={typeof window !== 'undefined' && window.location.pathname === "/investment-warehouse"} activeclassname="active" id="investment-warehouse" key="investment-warehouse" onClick={this.handleClick.bind(this, "investment-warehouse")} href="/investment-warehouse" >Investment Warehouse</NavLink>
                </NavItem>
                <NavItem >
                  <NavLink active={typeof window !== 'undefined' && window.location.pathname === "/leadership-team-and-advisory-board"} activeclassname="active" id="leadership-team-and-advisory-board" key="leadership-team-and-advisory-board" onClick={this.handleClick.bind(this, "leadership-team-and-advisory-board")} href="/leadership-team-and-advisory-board" >Team</NavLink>
                </NavItem>
              <NavItem >
                <NavLink active={typeof window !== 'undefined' && window.location.pathname === "/contact"} activeclassname="active" id="contact" key="contact" onClick={this.handleClick.bind(this, "contact")} href="/contact" >Contact</NavLink>
              </NavItem>
              </Nav>

            </Collapse>
          </div>
        </Navbar>
      </div>
    )
  }
}

export default Navigation
